import { getCalculation } from '@/grid/calculation';

export function maybe<T>(fn: (value: string) => T): (value: string | null | undefined, defaultTo?: T) => T {
  return (value, defaultTo) => {
    try {
      return value === null || value === undefined ? defaultTo! : fn(value);
    } catch (error) {
      console.error(error, value, defaultTo);
      return defaultTo!;
    }
  };
}

export function compose<Input, Inner, Output>(
  outer: (value: Inner) => Output,
  inner: (value: Input) => Inner
): (input: Input) => Output {
  return (input) => outer(inner(input));
}

export function debounce<T extends (...args: any[]) => void>(fn: T, ms: number): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout> | undefined;
  return function (...args: Parameters<T>) {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn(...args), ms);
  };
}

export function immediateThenDebounce<T extends (...args: any[]) => void>(
  fn: T,
  ms: number
): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout> | undefined;
  let isFirstCall = true; // Track whether it's the first call

  return function (...args: Parameters<T>) {
    if (isFirstCall) {
      // Execute the function immediately for the first call
      fn(...args);
      isFirstCall = false; // Mark the first call as done
    } else {
      // Clear the existing timeout
      clearTimeout(timeout);

      // Set a new timeout and execute the function after the debounce period
      timeout = setTimeout(() => {
        fn(...args);
        isFirstCall = true; // Reset for the next set of calls
      }, ms);
    }
  };
}

export function unique<T>(array: Array<T>): Array<T> {
  return Array.from(new Set(array));
}

export function getRandomUUID(): string {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  return uint32.toString(16);
}

export const operatorInserter = (operator: string, start: number, end: number): ((input: string) => string) => {
  let format = (input: string) => {
    return input.slice(0, start) + operator + input.slice(end);
  };
  if (operator === '()' && start !== end) {
    format = (input) => {
      return input.slice(0, start) + '(' + input.slice(start, end) + ')' + input.slice(end);
    };
  }
  return format;
};

export function beautifyExpression(expression: string): string {
  const [isIfElse, ifElseExpression] = getCalculation(expression);
  if (!isIfElse || !ifElseExpression) {
    return expression;
  }

  let result = '';

  result += `if (${ifElseExpression.ifExpression})\n`;
  result += `then (${ifElseExpression.thenExpression})\n`;

  for (let elseIfExpression of ifElseExpression.elseIfExpressions) {
    result += `elseif (${elseIfExpression.ifExpression})\n`;
    result += `then (${elseIfExpression.thenExpression})\n`;
  }

  result += `else (${ifElseExpression.elseExpression})`;

  return result;
}

export function removeWhiteSpaces(string: string) {
  if (!string) return string;
  // remove all whitespace characters (tab, newline, carriage return, form feed)
  return string.replace(/[\t\n\r\f]+/g, '');
}

export function getBase64ImageFromURL(url: string): Promise<string> {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    // img.setAttribute('crossOrigin', 'anonymous');
    img.src = window.location.origin + `/proxy-image?url=${encodeURI(url)}`;

    img.onload = () => {
      var canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext('2d');
      ctx?.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL('image/png');

      resolve(dataURL);
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
}

export async function generateAllBase64ImagesFromArray(data: string[][], base64: { [x: string]: string }) {
  if (!data) return;

  let itemsDone = 0;

  await Promise.all(
    // Each item in the data array is an row of urls
    // We need to get the base64 for each url in the row
    data.flatMap(async (item, _, { length }) => {
      // Loop through each row and get the base64 for each url
      if (item.length > 0) {
        for (const url of item) {
          base64[url] ??= await getBase64ImageFromURL(url).catch(() => '');

          // Update the progress bar with the percentage of items done
          itemsDone += 1 / item.length;
          const exportPercentageComplete = document.getElementById('ExportPercentageComplete');
          if (exportPercentageComplete)
            exportPercentageComplete.innerHTML =
              'Processed: <strong>' + Math.round((itemsDone / length) * 100) + '%</strong>';
        }
      }
    })
  );

  return base64;
}

export function generateRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}
