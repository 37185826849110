export const FETCH_METHODS = {
  All_at_once: 0,
  Loading_Screen: 1,
  // Stream: 2
};
import type { Worksheet } from '@tableau/extensions-api-types';

function resolvePath(href: string) {
  let link = document.createElement('a');
  link.href = href;
  return link.href;
}

let CONFIG_URL = resolvePath('./config.html');

// Remove Tableau AGG from fieldNames
//ToDo: Rewrite this function to be cleaner
const BETWEEN_PARENTHS = /\(.*\)$/;
export function removeAgg(string: string = '', dataType: string | null = null) {
  // only parse measures float / ints
  if (dataType === null || dataType === 'float' || dataType === 'int') {
    if (BETWEEN_PARENTHS.exec(string)) {
      string = BETWEEN_PARENTHS.exec(string)?.[0] ?? '';
    }
  }
  return string;
}

export function openConfig(): Object {
  window.tableau.extensions.ui
    .displayDialogAsync(CONFIG_URL, 'null', {
      height: 900,
      width: 1050,
      //@ts-ignore can't seem to import the dailogStyle from the tableau
      dialogStyle: 'modeless',
    })
    .then((closePayload) => {
      console.log('dialog closed', closePayload);
    })
    .catch((error) => {
      switch (error.errorCode) {
        case window.tableau.ErrorCodes.DialogClosedByUser:
          break;
        default:
          console.error(error.message);
      }
    });
  return {};
}

let IF_ELSE_EDITOR_URL = resolvePath('./ifElseEditor.html');

export async function openIfElseEditor(data?: string) {
  try {
    const closePayload = await window.tableau.extensions.ui.displayDialogAsync(IF_ELSE_EDITOR_URL, data, {
      height: 700,
      width: 660,
      //@ts-ignore can't seem to import the dailogStyle from the tableau
      dialogStyle: 'modeless',
    });
    return closePayload;
  } catch (error: any) {
    switch (error.errorCode) {
      case window.tableau.ErrorCodes.DialogClosedByUser:
        return 'closedByUser';
      case window.tableau.ErrorCodes.DialogAlreadyOpen:
        return 'alreadyOpen';
      default:
        console.error(error.message);
    }
    return '';
  }
}

export function getParametersAsync() {
  const extension = window.tableau.extensions;
  const isVizExtension = !!extension.worksheetContent;

  return isVizExtension
    ? extension.worksheetContent?.worksheet.getParametersAsync()
    : extension.dashboardContent?.dashboard.getParametersAsync();
}

export function findParameterAsync(name?: string) {
  const extension = window.tableau.extensions;
  const isVizExtension = !!extension.worksheetContent;

  if (!name) return isVizExtension;

  return isVizExtension
    ? extension.worksheetContent?.worksheet.findParameterAsync(name)
    : extension.dashboardContent?.dashboard.findParameterAsync(name);
}

export async function getEncodingMap(worksheet: Worksheet) {
  const visualSpec = await worksheet.getVisualSpecificationAsync();

  const encodingMap: { [key: string]: any } = {};
  const columnEncoding: { [key: string]: any } = {};
  if (visualSpec.activeMarksSpecificationIndex < 0) return { columnEncoding, encodingMap };

  const marksCard = visualSpec.marksSpecifications[visualSpec.activeMarksSpecificationIndex];
  for (const encoding of marksCard.encodings) {
    if (columnEncoding[encoding.field.fieldId]) {
      columnEncoding[encoding.field.fieldId].encodingTypes.push(encoding.id);
    } else {
      columnEncoding[encoding.field.fieldId] = {
        ...encoding.field,
        encodingTypes: [encoding.id],
      };
    }

    if (Array.isArray(encodingMap[encoding.id])) {
      encodingMap[encoding.id].push(encoding.field);
    } else {
      encodingMap[encoding.id] = [encoding.field];
    }
  }

  return { columnEncoding, encodingMap };
}

async function getSummaryDataTable(worksheet, setLoadingProgress, summaryDataOptions) {
  let data = [];
  let columns;
  const PAGE_SIZE = 10000;

  // Fetch the summary data using the DataTableReader
  const dataTableReader = await worksheet.getSummaryDataReaderAsync(PAGE_SIZE, summaryDataOptions);
  const totalPages = dataTableReader.pageCount;

  for (let currentPage = 0; currentPage < totalPages; currentPage++) {
    const dataTablePage = await dataTableReader.getPageAsync(currentPage);
    if (!columns) columns = dataTablePage.columns;
    data = data.concat(dataTablePage.data);

    const percentComplete = Math.round(((currentPage + 1) / totalPages) * 100);
    if (setLoadingProgress) setLoadingProgress(percentComplete);
  }
  await dataTableReader.releaseAsync();

  return { data, columns };
}

export async function getSummaryDataAsync(datasheet, settings, setLoadingProgress: any = null) {
  const extension = window.tableau.extensions;
  const isVizExtension = !!extension.worksheetContent;
  const tableauContext = extension.environment.context;
  const tableauVersion = extension.environment.tableauVersion;
  const includeDataValuesOption = settings.onlyNativeValues ? tableau.IncludeDataValuesOption.OnlyNativeValues : false;
  // if tableau cloud or using desktop >= 2024.1 with loading screen option
  const canFetchDataReader =
    (tableauContext === 'server' && (tableauVersion === '0.0.0' || parseFloat(tableauVersion) >= 2024.1)) ||
    (tableauContext === 'desktop' && parseFloat(tableauVersion) >= 2024.1);

  const summaryDataOptions = {
    includeDataValuesOption,
    ignoreSelection: isVizExtension,
  };

  if (canFetchDataReader && settings.fetchMethod === FETCH_METHODS.Loading_Screen) {
    return await getSummaryDataTable(datasheet, setLoadingProgress, summaryDataOptions);
  }
  return await datasheet.getSummaryDataAsync(summaryDataOptions);
}

// @ts-ignore
// heb je de code gejat? Dan gaat onderstaande in werking.
export function checkHostName(bouwType) {
  const w = window,
    tb = w.atob,
    d = document;
  d.loc = d[tb('bG9jYXRpb24')];

  // sandbox testen? Pak dan onderstaande code voor const s;
  // const s = tb(
  //   'WlhoMFpXNXphVzl1Y3k1aGNIQnpabTl5ZEdGaWJHVmhkUzVqYjIwPXxiRzlqWVd4b2IzTjB8WlhoMFpXNXphVzl1Y3k1MFlXSnNaV0YxZFhObGNtTnZiblJsYm5RdVkyOXQ'
  // )

  /* Sandbox @ Saleforce use the following: */
  //  [ "extensions.tableauusercontent.com", "stage-extensions.tableauusercontent.com" ]
  const x =
    bouwType == 's'
      ? tb(
          'WlhoMFpXNXphVzl1Y3k1MFlXSnNaV0YxZFhObGNtTnZiblJsYm5RdVkyOXR8YzNSaFoyVXRaWGgwWlc1emFXOXVjeTUwWVdKc1pXRjFkWE5sY21OdmJuUmxiblF1WTI5dA=='
        )
      : // productie hostname check:
        tb(
          'WW1WMFlTNWxlSFJsYm5OcGIyNXpMV0Z3Y0hObWIzSjBZV0pzWldGMUxtbHVabTkwYjNCcFkzTXVZMjl0fFpYaDBaVzV6YVc5dWN5NWhjSEJ6Wm05eWRHRmliR1ZoZFM1amIyMD18WlhoMFpXNXphVzl1Y3kxaGNIQnpabTl5ZEdGaWJHVmhkUzVwYm1admRHOXdhV056TG1OdmJRPT0='
        );
  const s = x.split('|').map(w[tb('YXRvYg')]);

  const h = d['loc']['emantsoh'.split('').reverse().join('')];
  // document.location.hostname: e.g. tableausuercontent.com, extension.appsfortableau.com
  if (bouwType !== 'e' && bouwType !== 'b' && import.meta.env.MODE !== 'development' && !s.includes(h)) {
    // toevoegen fingerprint informatie van de klant clientJS. en via een post naar onze machine sturen
    document.querySelectorAll('script,link').forEach((x) => x.remove());
    document.documentElement.innerHTML = '';
    throw new Error('');
  }
}

export async function getUsername(username): Promise<string> {
  const extension = window.tableau.extensions;
  const isVizExtension = !!extension.worksheetContent;
  const tableauWorksheets = isVizExtension ? [] : extension.dashboardContent!.dashboard.worksheets;

  if (!username?.column || (!isVizExtension && !username?.datasheet)) {
    return '';
  }

  const sheet = isVizExtension
    ? extension.worksheetContent!.worksheet
    : tableauWorksheets.find((sheet) => sheet.name === username?.datasheet);

  const summaryData = await sheet?.getSummaryDataAsync();

  // get the username value
  if ((summaryData?.columns?.length ?? 0) > 0 && username?.column) {
    const col = summaryData?.columns?.find((col) => col.fieldName === username?.column);
    if (col) {
      return summaryData?.data[0][col.index].formattedValue ?? '';
    }
    return '';
  }
  return '';
}

export function onDesktop() {
  return window.tableau.extensions.environment?.context
    ? window.tableau.extensions.environment.context === 'desktop'
    : navigator.userAgent.includes('Tableau Desktop');
}
